export const filters = [
	[
		{
			label: '来源渠道',
			type: 'select',
			attr: 'source',
			placeholder: '请选择',
			column: 6,
			option: [{ label: '系统分配', val: 1 }],
			labelWidth: '74px',
		},
		{
			type: 'button',
			column: 4,
		},
	],
]

export const tableColumn = [
	{
		label: '序号',
		width: '120px',
		isCustomize: true,
		tableType: 'no',
	},
	{
		label: '销售',
		prop: 'nickname',
	},
	{
		label: '个人新数据开关',
		prop: 'customerSwitch',
        isCustomize: true,
        tableType: 'customerSwitch'
	},
	{
		label: '日最大接收量',
		prop: 'customerDailyMax',
		isCustomize: true,
		tableType: 'dayNum',
	},
	{
		label: '已接收数据数量',
		prop: 'customerDailyActualMax',
	},
	{
		label: '明细',
		isCustomize: true,
		tableType: 'theadedOpeartion',
	},
]
