<template>
  <FilterForm
    :formData="filterData"
    :filters="filters"
    @submit="handleSubmit"
    @reset="handleReset"
  />

  <TheadedTable
    :tableColumn="tableColumn"
    :tableData="tableData"
    @nameDrawer="handleNameDrawer"
    :page="pagination.page"
    :size="pagination.size"
    @changeInput="handleChange"
  />
  <TheadedPagination
    :total="total"
    @currentChange="currentChange"
    @sizeChange="sizeChange"
  />

  <CustomerDetail
    width="1200px"
    :isShowDialog="isShowDrawer"
    @close="handleDrawerClose"
    direction="rtl"
    :userId="drawerId"
  />

</template>

<script>
import FilterForm from "@/components/filter";
import TheadedTable from "@/components/table";
import TheadedPagination from "@/components/pagination";
import { computed, onMounted, reactive, ref } from "vue";
import { filters, tableColumn } from "./index.js";
import { useStore } from "vuex";
import { reset } from "@/until";
import CustomerDetail from "@/components/drawer-form/index.vue";
import { putDailyMax } from "@/api/method/customer";
import { getChannelList } from "@/api/method/channel";
import { ElMessage } from "element-plus";

export default {
  name: "Theaded",
  props: ["options", "departments"],
  components: {
    FilterForm,
    TheadedTable,
    TheadedPagination,
    CustomerDetail,
  },

  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      source: null,
    });

    onMounted(() => {
      getList();
      channelList();
    });
    const pagination = reactive({
      page: 1,
      size: 10,
    });
    const tableData = computed(() => state.theadedList && state.theadedList.records || []);
    const total = computed(() => state.theadedList && state.theadedList.total);
    const getList = () => {
      dispatch("theadedList", {
        ...filterData,
        ...pagination,
      });
    };
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    const channelList = async () => {
      const res = await getChannelList();
      filters[0][0].option = res.data.map((item) => ({
        label: item.name,
        val: item.id,
      }));
    };

    const isShowDrawer = ref(false);
    const handleDrawerClose = () => (isShowDrawer.value = false);
    const drawerId = ref(null);
    const handleNameDrawer = (row) => {
      isShowDrawer.value = true;
      drawerId.value = row.costumerId;
    };

    const handleChange = async (row) => {
      const id = row.id;
      const dailyMax = row.customerDailyMax;

      const res = await putDailyMax({ id, dailyMax });
      if (res.code == 200) {
        ElMessage.success("修改成功！");
        getList();
      } else {
        ElMessage.error(res.msg);
      }
    };

    return {
      filters,
      filterData,
      handleSubmit,
      handleReset,
      tableData,
      tableColumn,
      total,
      isShowDrawer,
      handleDrawerClose,
      handleNameDrawer,
      drawerId,
      currentChange,
      sizeChange,
      pagination,
      handleChange,
    };
  },
};
</script>

<style>
</style>